import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import Heading from "./components/Main/TextElement/Heading/Heading";
import AOS from "aos";
import "aos/dist/aos.css";
// Lazy load components
const Home = lazy(() => import("./pages/Home/Home"));
const SOS = lazy(() => import("./pages/Products/SOS/SOS"));
const Cronology = lazy(() => import("./pages/Products/Cronology/Cronology"));
const Detox = lazy(() => import("./pages/Products/Detox/Detox"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Alquimia = lazy(() => import("./pages/Products/Alquimia/Alquimia"));
const Lovely = lazy(() => import("./pages/Products/Lovely/Lovely"));
const TheFirst = lazy(() => import("./pages/Products/TheFirst/TheFirst"));
const Academy = lazy(() => import("./pages/Academy/Academy"));

AOS.init({
  duration: 1000, // czas trwania animacji w milisekundach
});

export default function App() {
  return (
    <BrowserRouter basename="/">
      <Suspense
        fallback={
          <Heading type="h2" items={[]} htmlFor="">
            Ładowanie...
          </Heading>
        }
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/o-nas" element={<AboutUs />} />
          <Route path="/produkty/sos" element={<SOS />} />
          <Route path="/produkty/cronology" element={<Cronology />} />
          <Route path="/produkty/detox" element={<Detox />} />
          <Route path="/produkty/alquimia" element={<Alquimia />} />
          <Route path="/produkty/lovely" element={<Lovely />} />
          <Route path="/produkty/the-first" element={<TheFirst />} />
          <Route path="/akademia" element={<Academy />} />
          <Route path="/salony-partnerskie" element={<Home />} />
          <Route path="/kontakt" element={<Home />} />
          <Route path="/blog" element={<Home />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
